import React from 'react'
import _ from 'lodash'
import { Grid, Button, Icon, Dropdown } from 'semantic-ui-react'
import classnames from 'classnames'
import SEO from '../../components/SEO'
import Logo from '../../images/logos/contego-logo.png'

const VIDEO_LIST = [
  {
    index: 0,
    model: 'magna',
    title: 'Magna (Cappuccino on Mocha Base)',
    price: '4,395',
    src: 'https://player.vimeo.com/video/440452541'
  },
  {
    index: 1,
    model: 'magna',
    title: 'Magna (Ivory on Pearl Base)',
    price: '4,395',
    src: 'https://player.vimeo.com/video/440452601'
  },
  {
    index: 2,
    model: 'vsportt',
    title: 'VSPORT.T (Ivory on Pearl TERGO Base)',
    price: '3,495',
    src: 'https://player.vimeo.com/video/440452364'
  },
  {
    index: 3,
    model: 'vsportt',
    title: 'VSPORT.T (Black on Gold TERGO Base)',
    price: '3,495',
    src: 'https://player.vimeo.com/video/440452275'
  },
  {
    index: 4,
    model: 'vsportn',
    title: 'VSPORT.N (Black on Gold NOVO Base)',
    price: '3,495',
    src: 'https://player.vimeo.com/video/440452407'
  },
  {
    index: 5,
    model: 'vsportn',
    title: 'VSPORT.N (Fossil Gray on Graphite NOVO Base)',
    price: '3,495',
    src: 'https://player.vimeo.com/video/440452472'
  },
  {
    index: 6,
    model: 'tergo',
    title: 'TERGO (Ivory on Pearl NOVO Base)',
    price: '3,295',
    src: 'https://player.vimeo.com/video/440452223'
  },

  {
    index: 7,
    model: 'tergo',
    title: 'TERGO (Dark Chocolate on Graphite NOVO Base)',
    price: '3,295',
    src: 'https://player.vimeo.com/video/440505861'
  },
  {
    index: 10,
    model: 'vip',
    title: 'VIP Bench',
    price: '4,995',
    src: 'https://player.vimeo.com/video/440461256'
  },
  {
    index: 11,
    model: 'furniture',
    title: 'Furniture',
    src: 'https://player.vimeo.com/video/439408088'
  }
]

const MODEL_OPTIONS = [
  { key: 'magna', text: 'Magna', value: 'magna' },
  { key: 'vsportn', text: 'VSPORT.N', value: 'vsportn' },
  { key: 'vsportt', text: 'VSPORT.T', value: 'vsportt' },
  { key: 'tergo', text: 'Tergo', value: 'tergo' },
  { key: 'vip', text: 'Vip', value: 'vip' },
  { key: 'furniture', text: 'Furniture', value: 'furniture' }
]
const OurProducts = () => {
  const [activeIndex, setActiveIndex] = React.useState()
  const [filteredData, setFilteredData] = React.useState([])

  const handleStepBackward = React.useCallback(() => {
    activeIndex - 1 >= 0 ? setActiveIndex(activeIndex => activeIndex - 1) : activeIndex
  }, [activeIndex])

  const handleStepForward = React.useCallback(() => {
    activeIndex + 1 < filteredData.length
      ? setActiveIndex(activeIndex => activeIndex + 1)
      : activeIndex
  }, [activeIndex, filteredData.length])

  const handleChangeModel = React.useCallback((e, { value }) => {
    setFilteredData(_.filter(VIDEO_LIST, video => value === video.model))
    setActiveIndex(0)
  }, [])

  return (
    <div className="container-fluid">
      <SEO
        title="CONTÉGO PRODUCTS?"
        description="Pedicure Spa Chairs and Nail Salon Funitures"
        meta={[
          {
            name: 'keywords',
            content: 'nail salon furniture, pedicure spa chair, spa chair, nail salon equipment'
          }
        ]}
      />
      <div className="row">
        <div className="col">
          <Grid columns="1" stackable className="py-4">
            <Grid.Column verticalAlign="middle">
              <img src={Logo} alt="contego logo" width="150px" height="auto" />
              <h1 className="text-blue">Pick Products You Want To See</h1>
              <Dropdown
                placeholder="Product Name"
                fluid
                selection
                options={MODEL_OPTIONS}
                onChange={handleChangeModel}
              />

              <br />

              {filteredData.length > 0 && (
                <>
                  <div className="playlist-controller bg-light py-2 px-2 ">
                    <h4>
                      {activeIndex + 1} of {filteredData.length}
                    </h4>

                    <div>
                      <Button onClick={handleStepBackward} icon>
                        <Icon name="step backward" />
                      </Button>

                      <Button onClick={handleStepForward} icon>
                        <Icon name="step forward" />
                      </Button>
                    </div>
                  </div>

                  <div className="shadow-sm py-2 px-2 my-2 bg-dark rounded">
                    {filteredData.map((video, index) => {
                      return (
                        <a
                          onClick={() => {
                            setActiveIndex(index)
                          }}
                          href="#video"
                          className={classnames([
                            'd-block my-2 text-left bg-white p-2 rounded text-medium',
                            activeIndex === index && 'active-dark'
                          ])}
                        >
                          {index + 1}. {video.title}
                        </a>
                      )
                    })}
                  </div>
                </>
              )}

              {filteredData.length > 0 && (
                <>
                  <div id="video" className="shadow rounded pb-2">
                    {filteredData.map((video, index) => {
                      return (
                        <>
                          <iframe
                            title={video.title}
                            src={video.src}
                            allowFullScreen
                            frameBorder="0"
                            width="100%"
                            height="450"
                            autoPlay
                            allow="autoplay; fullscreen"
                            className={classnames([
                              'animated zoomInRight',
                              activeIndex === index ? 'visible' : 'hidden'
                            ])}
                          />
                          <h4
                            className={classnames([
                              'animated zoomInRight my-0 px-2 text-center',
                              activeIndex === index ? 'visible' : 'hidden'
                            ])}
                          >
                            USEFUL DESIGN & FEATURES
                          </h4>

                          {video.model !== 'furniture' && (
                            <h2
                              className={classnames([
                                'text-secondary my-0 animated zoomInRight px-2 text-center',
                                activeIndex === index ? 'visible' : 'hidden'
                              ])}
                            >
                              {video.price}
                            </h2>
                          )}
                        </>
                      )
                    })}
                  </div>

                  <a
                    href="https://www.facebook.com/pg/contegospa/photos/"
                    rel="noopener noreferrer"
                    className="bg-secondary text-light p-2 d-block text-center"
                  >
                    CHECK OUT MORE IMAGES
                  </a>
                </>
              )}
            </Grid.Column>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default OurProducts
